
import { Vue, Component } from "vue-property-decorator";
import { UserService } from "@/api/UserServiceApi";
import InputField from "@/components/InputField.vue";

@Component({
  components: {
    InputField
  }
})
export default class PasswordReset extends Vue {
  password: string = "";
  passwordConfirmation: string = "";
  code: string = "";

  created() {
    let code = this.$route.query.code as string;
    if (code == null || code.length == 0) {
      this.$router.push("/");
    }

    this.code = code;
  }

  async resetPassword() {
    if (this.password != this.passwordConfirmation) {
      return;
    }

    if (this.password) {
      await UserService.ResetPassword({
        code: this.code,
        password: this.password
      })
        .then(() => {
          this.$swal.fire({
            titleText: this.$t("MODAL.PASSWORD_RESET_SUCCESS.TITLE").toString(),
            text: this.$t("MODAL.PASSWORD_RESET_SUCCESS.SUB_TITLE").toString(),
            confirmButtonText: "Ok",
            icon: "success"
          });
          this.$router.push("/login");
        })
        .catch(() => {
          this.$swal.fire({
            titleText: this.$t("MODAL.PASSWORD_RESET_ERROR.TITLE").toString(),
            text: this.$t("MODAL.PASSWORD_RESET_ERROR.SUB_TITLE").toString(),
            confirmButtonText: "Ok",
            icon: "info"
          });
          this.$router.push("/forgot-password");
        });
    }
  }
}
